import axios from 'axios'
import router from '@/router'
import { tsToTime } from '@/utils/timeUtil'
import { useUserStore } from '@/stores/user'
import { getRocketChatWebhook, getTimeZone } from '@/utils/env'

const WEBHOOK_URL = getRocketChatWebhook()

export async function send_catch_err(errorDesc, errorStack) {
  if (!WEBHOOK_URL || WEBHOOK_URL === '') {
    return
  }

  const message = `
# 捕捉到浏览器错误
网页时间： ${formatDate(new Date())}
网页时间： ${formatDate(tsToTimezoneDate(Date.now()))}
中国时间： ${formatDate(tsToTimezoneDate(Date.now(), 8))}

当前页面： ${router.currentRoute.value.fullPath}
登录账号： ${useUserStore().account}

错误信息：
\`\`\`log
${errorDesc}
\`\`\`

错误堆栈：
\`\`\`log
${errorStack}
\`\`\`
`.trim()

  await axios.post(
    WEBHOOK_URL,
    { text: message },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export function send_fs_server_err(title, url, http_status = 0, response = null, error_info = null) {
  if (url === '/api/admin/user/keepOnline' || url === '/stat/admin/acc' || url === '/stat/top') {
    return
  }

  let message = `
# ${title}
网页时间： ${formatDate(new Date())}
韩国时间： ${formatDate(tsToTimezoneDate(Date.now()))}

请求地址： ${url}
登录账号： ${useUserStore().account}
`.trim()

  if (http_status > 0) {
    message += `
响应状态： ${http_status}`
  }

  if (response !== null) {
    message += `
响应内容：
\`\`\`json
${JSON.stringify(response)}
\`\`\`
`
  }

  if (error_info !== null) {
    message += `
错误信息：
\`\`\`log
${error_info}
\`\`\`
`
  }

  axios
    .post(
      WEBHOOK_URL,
      { text: message },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(() => {})
    .catch((error) => {})
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hour = String(date.getHours()).padStart(2, '0')
  const minute = String(date.getMinutes()).padStart(2, '0')
  const second = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

function tsToTimezoneDate(timestamp, timezone = getTimeZone()) {
  if (!timestamp || timestamp === 0) {
    return undefined
  }

  if ((timestamp + '').length === 10) {
    timestamp = timestamp * 1000
  }

  // 创建一个基于UTC时间的Date对象
  const date = new Date(timestamp)

  // 设置目标时区的小时偏移量
  const offsetHours = parseInt(timezone)

  // 获取本地时间和UTC时间之间的毫秒偏移量
  const localOffsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000

  // 计算新的时间戳，代表目标时区的时间
  const newTimestamp = date.getTime() + localOffsetInMilliseconds + offsetHours * 60 * 60 * 1000

  // 创建并返回一个新的Date对象，其时间等于目标时区的时间
  return new Date(newTimestamp)
}
