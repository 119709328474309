import { config } from '@/config'
import { getTimeZone } from '@/utils/env'

/**
 * 时间戳到指定时区的Date
 * @param timestamp
 * @param timezone 指定时区，默认未config.timezone
 * @returns {Date}
 */
export function tsToTimezoneDate(timestamp, timezone = getTimeZone()) {
  if (!timestamp || timestamp === 0) {
    return undefined
  }

  if ((timestamp + '').length === 10) {
    timestamp = timestamp * 1000
  }

  // 创建一个基于UTC时间的Date对象
  const date = new Date(timestamp)

  // 设置目标时区的小时偏移量
  const offsetHours = parseInt(timezone)

  // 获取本地时间和UTC时间之间的毫秒偏移量
  const localOffsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000

  // 计算新的时间戳，代表目标时区的时间
  const newTimestamp = date.getTime() + localOffsetInMilliseconds + offsetHours * 60 * 60 * 1000

  // 创建并返回一个新的Date对象，其时间等于目标时区的时间
  return new Date(newTimestamp)
}

/**
 * 时间戳到指定时区的时间文本
 * @param timestamp 时间戳
 * @returns {String}
 */
export function tsToTime(timestamp) {
  const date = tsToTimezoneDate(timestamp)
  if (date === undefined) {
    return '-----'
  }
  try {
    return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
  } catch (error) {
    return '-----'
  }
}

/**
 * 模拟目标时区时间戳 到 本地时间戳，本方法用于模拟UI时间选择的组件 选择的时间为指定时区的时间
 * @param timestamp
 * @param targetTimezoneOffset
 * @returns {undefined|number}
 */
export function tsToTargetTimezone(timestamp, targetTimezoneOffset = getTimeZone()) {
  if (!timestamp || timestamp === 0) {
    return undefined
  }

  targetTimezoneOffset = parseInt(targetTimezoneOffset)

  if ((timestamp + '').length === 10) {
    timestamp = timestamp * 1000
  }

  // console.log('timestamp:', timestamp)
  // 获取本地时区偏移（以分钟为单位）
  const localTimezoneOffset = new Date().getTimezoneOffset()
  // console.log('获取本地时区偏移（以分钟为单位）:', localTimezoneOffset)

  // 计算本地时区偏移（以毫秒为单位）
  const localOffsetInMillis = localTimezoneOffset * 60 * 1000
  // console.log('计算本地时区偏移（以毫秒为单位）:', localOffsetInMillis)

  // 计算目标时区偏移（以毫秒为单位）
  const targetOffsetInMillis = targetTimezoneOffset * 60 * 60 * 1000
  // console.log('计算目标时区偏移（以毫秒为单位）:', targetOffsetInMillis)

  // 计算时间差（以毫秒为单位）
  const timeDifference = targetOffsetInMillis + localOffsetInMillis
  // console.log('计算时间差（以毫秒为单位）', timeDifference)

  // 调整时间戳
  const adjustedTimestamp = timestamp - timeDifference
  // console.log('调整时间戳）', adjustedTimestamp)
  return adjustedTimestamp
}
