import { createI18n } from 'vue-i18n'
import { useAppStore } from '@/stores/app'
import { getDefaultLang } from '@/utils/env'

const allLanguages = ['en', 'zh', 'ko', 'th', 'vi', 'my', 'si']
const isDev = import.meta.env.MODE === 'development'

// 动态导入语言文件
const loadLanguage = async (lang) => {
  try {
    // const module = await import(`./${isDev ? 'global' : 'build'}/${lang}.js`)
    const module = await import(`./${isDev ? 'build' : 'build'}/${lang}.js`)
    return module.default
  } catch (error) {
    console.error(`加载语言文件失败: ${lang}`, error)
    return {}
  }
}

// 从本地数据中读取当前语言
function getLanguageFromLocalStorage() {
  const localStorageData = localStorage.getItem('app')
  if (localStorageData) {
    try {
      const { language } = JSON.parse(localStorageData)
      if (language && language.trim() !== '') {
        return language
      }
    } catch (error) {
      // Ignore JSON parse errors
    }
  }
  return getDefaultLang()
}

const i18n = createI18n({
  locale: getLanguageFromLocalStorage(),
  messages: {},
})

i18n.global.init = () => {
  const appStore = useAppStore()
  // const currentLanguage = appStore.language

  // const setLocale = async (lang) => {
  //   if (!i18n.global.messages[lang]) {
  //     const messages = await loadLanguage(lang)
  //     i18n.global.setLocaleMessage(lang, messages)
  //   }
  //   i18n.global.locale = lang
  // }
  //
  // setLocale(currentLanguage)

  appStore.initLanguage(allLanguages, (lang) => {
    if (!i18n.global.messages[lang]) {
      loadLanguage(lang).then((messages) => {
        const existingTranslations = i18n.global.messages[lang] || {}
        console.log(lang, ', ', messages)
        i18n.global.setLocaleMessage(lang, { ...existingTranslations, ...messages })
        i18n.global.locale = lang
      })
      return true
    } else {
      i18n.global.locale = lang
      return true
    }
  })
}

export default i18n
