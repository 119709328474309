/**
 * 默认配置
 */
export const config = {
  logo: {
    /** 是否显示 */
    display: true,
    /** 默认Logo文本, 可以使用国际化 */
    label: 'Admin',
    /** 可以是svg，可以是Element的图标名字 */
    icon: '',
    /** 图标大小 */
    iconSize: 20,
  },
  color: {
    btnMain: '#374156',
    btnRed: '#be0000',
    btnGreen: '#009a48',
    grey: '#777777',
    btnYellow: '#ecaf00',
  },
  /** 网站模式: admin、website、mobile(该模式下只有手机版本) */
  websiteModel: 'admin',
  /** 无手机模式：没有开发手机版时开启 */
  noMobileModel: true,
  localMode: true,
  /** 导航栏相关 */
  router: {
    mobile: {
      /** 图标大小 */
      iconSize: 28,
    },
    /** 管理后台相关 */
    admin: {
      /** 背景颜色 */
      backgroundColor: '#fff',
      /** 默认字体颜色 */
      textColor: '#3d6e8f',
      /** 选中后字体颜色 */
      activeTextColor: '#1B9CFB',
      /** 图标大小 */
      iconSize: 18,
    },
  },
  elementPlusIconNames: [],
}
