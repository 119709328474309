import { config } from '@/config'
import { DateTime } from 'luxon'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import i18n from '@/i18n'
import { ref, computed } from 'vue'
import { http_post } from '@/axios'
import { tsToTimezoneDate } from '@/utils/timeUtil'

/**
 * 判断是否是移动设备
 * @returns {boolean}
 */
export function isMobile() {
  const userAgent = navigator.userAgent
  // 使用正则表达式匹配常见的移动设备关键词
  const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  const isMobilePlatform = mobileKeywords.test(userAgent)
  if (isMobilePlatform) {
    // 屏幕宽度小于500px使用手机显示
    return window.innerWidth < 500
  }
  return false // PC端
}

/**
 * 获取颜色反色
 * @param hexColor #FF0000
 * @returns {string}
 */
export function getColorInverse(hexColor) {
  const r = parseInt(`0x${hexColor.slice(1, 3)}`, 16)
  const g = parseInt(`0x${hexColor.slice(3, 5)}`, 16)
  const b = parseInt(`0x${hexColor.slice(5, 7)}`, 16)

  return `#${(255 - r).toString(16)}${(255 - g).toString(16)}${(255 - b).toString(16)}`
}

/**
 * 获取国际化文本
 * @param label 键
 * @param params 文本内的动态参数, 比如 { name: '123' }
 * @param local 指定语言, 默认为全局配置 比如 'zh'
 */
export function tryGetI18nText(label, params = undefined, local = undefined) {
  return computed(() => {
    if (!label) {
      return ''
    }

    if (i18n.global.te(label)) {
      if (params) {
        if (local) {
          return i18n.global.t(label, params, local)
        } else {
          return i18n.global.t(label, params)
        }
      } else {
        if (local) {
          return i18n.global.t(label, local)
        } else {
          return i18n.global.t(label)
        }
      }
    } else {
      let result = label
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const pattern = new RegExp(`{${key}}`, 'g')
          result = result.replace(pattern, params[key])
        }
      }
      return result
    }
  }).value
}

export default function toBr(remark) {
  if (remark) {
    return remark.replace(/\n|\r\n/g, '<br>').replace(/ /g, '  ')
  }
  return ''
}

/**
 * 获取国际化文本
 * @param key 键
 * @param params 文本内的动态参数, 比如 { name: '123' }
 * @param local 指定语言, 默认为全局配置 比如 'zh'
 */
export function getI18nText(key, params, local) {
  if (params) {
    if (local) {
      return i18n.global.t(key, params, local)
    } else {
      return i18n.global.t(key, params)
    }
  } else {
    if (local) {
      return i18n.global.t(key, local)
    } else {
      return i18n.global.t(key)
    }
  }
}

/**
 * 获取文本宽度
 * @param text 文本内容
 * @param font
 * @returns {number} 宽度 100
 */
export function getTextWidth(text, font = null) {
  // 创建一个临时的 div 元素
  let element = document.createElement('div')
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'
  element.style.width = 'auto'
  element.style.whiteSpace = 'nowrap'
  if (font) {
    element.style.font = font
  }
  element.textContent = text

  // 将元素添加到文档中，计算宽度，然后移除
  document.body.appendChild(element)
  const width = element.offsetWidth
  document.body.removeChild(element)

  return width
}

/**
 *
 * @param texts 文本数组
 * @param font 字体
 * @returns {number} 宽度 100
 */
export function getTextListMaxWidth(texts, font = null) {
  let max = 0
  texts.forEach((item) => {
    const w = getTextWidth(item, font)
    if (w > max) {
      max = w
    }
  })
  return max
}

let userStore = null
let appStore = null

function getUserStore() {
  if (!userStore) {
    userStore = useUserStore()
  }
  return userStore
}

function getAppStore() {
  if (!appStore) {
    appStore = useAppStore()
  }

  return appStore
}

/**
 * 检查是否有权限，针对当前页面
 * @param level
 */
export function hasPermission(level) {
  if (getUserStore().isDev) {
    return true
  }
  const routerName = getAppStore().routerName
  const permission = getUserStore().permission[routerName]
  if (!permission) {
    return false
  }

  const i = parseInt(level)
  if (isNaN(i)) {
    return false
  }

  return (permission & (1 << i)) !== 0
}

export function toNumber(str) {
  if (!str || str === null || str === '') {
    return undefined
  }
  try {
    return parseFloat(str)
  } catch (e) {
    return undefined
  }
}

/** 以金钱格式显示 */
export function toMoney(number, saveFloatLength = 0) {
  // 检查 number 是否为 null 或 undefined
  if (number === null || number === undefined) {
    return '0'
  }

  // 尝试将 number 转换为数字
  const num = parseFloat(number)

  // 检查转换后的数字是否为有效的数字
  if (isNaN(num)) {
    return '0'
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: saveFloatLength,
    maximumFractionDigits: saveFloatLength,
  }).format(num)
}

/**
 * 以百分比方式显示
 * @param number 希望转成百分比的数字
 * @param floatLength 转换后保留小数点位数
 * @returns {string} 0.1%
 */
export function toPercent(number, floatLength = 0) {
  // 检查 number 是否为 null 或 undefined
  if (number === null || number === undefined) {
    return '---'
  }

  // 尝试将 number 转换为数字
  const num = parseFloat(number)

  // 检查转换后的数字是否为有效的数字
  if (isNaN(num)) {
    return '---'
  }

  const f = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: floatLength > 0 ? floatLength + 2 : 2,
    maximumFractionDigits: floatLength > 0 ? floatLength + 2 : 2,
  }).format(num)

  let result = (f * 100).toFixed(floatLength)
  return result + '%'
}

/**
 * 取路径的父路径
 * @param path 路径
 * @returns {string} 父路径
 */
export function getParentPath(path) {
  // 将所有反斜杠替换为斜杠
  const normalizedPath = path.replace(/\\/g, '/')

  // 查找最后一个斜杠的位置
  const lastSlashIndex = normalizedPath.lastIndexOf('/')

  // 截取从字符串开头到最后一个斜杠的位置
  return normalizedPath.substring(0, lastSlashIndex)
}

/** 获取品牌下拉框默认参数 */
export function getBrandOptions() {
  return new Promise((resolve, reject) => {
    http_post('/api/admin/brand/list', {}, false)
      .then((data) => {
        const options = []
        const selectAll = []
        for (const item of data.brands) {
          selectAll.push(item.id)
          options.push({ label: item.name, value: item.id })
        }
        resolve({ options, selectAll })
      })
      .catch(() => {
        reject()
      })
  })
}

/** 检查文本是否为邮件 */
export function checkIsEmail(email) {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return regex.test(email)
}

/**
 * 生成唯一ID
 * @returns {string} 唯一ID
 */
export function randomId() {
  // 获取当前时间戳
  const timestamp = Date.now().toString(36)
  // 生成随机字符串
  const randomString = Math.random().toString(36).substring(2, 7)
  // 组合时间戳和随机字符串作为唯一ID
  return timestamp + randomString
}

export function randomNum(min, max) {
  // 验证参数是否有效
  if (min >= max) {
    console.error('最小值必须小于最大值')
    return null
  }

  // 生成指定范围内的随机数
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * 判断两个变量是否相等
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function isEqual(value1, value2) {
  // 检查类型是否相等
  if (typeof value1 !== typeof value2) {
    return false
  }

  // 检查 null 和 undefined
  if (value1 === null && value2 === null) {
    return true
  }

  if (value1 === undefined && value2 === undefined) {
    return true
  }

  // 检查数组类型
  if (Array.isArray(value1) && Array.isArray(value2)) {
    // 检查数组长度是否相等
    if (value1.length !== value2.length) {
      return false
    }

    // 递归比较数组元素
    for (let i = 0; i < value1.length; i++) {
      if (!isEqual(value1[i], value2[i])) {
        return false
      }
    }

    return true
  }

  // 检查对象类型
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    // 比较对象属性个数
    const keys1 = Object.keys(value1)
    const keys2 = Object.keys(value2)

    if (keys1.length !== keys2.length) {
      return false
    }

    // 递归比较对象属性值
    for (let key of keys1) {
      if (!value2.hasOwnProperty(key) || !isEqual(value1[key], value2[key])) {
        return false
      }
    }

    return true
  }

  // 普通类型直接比较值
  return value1 === value2
}

/**
 * 本地选择的文件转成base64
 * @param file 选择的文件 比如 file.raw
 */
export function readFile(file) {
  return new Promise((resolve, reject) => {
    try {
      // 使用 FileReader 读取文件的字节数据
      const reader = new FileReader()
      reader.onload = (event) => {
        const byteData = event.target.result // 这是一个 ArrayBuffer
        const base64Data = btoa(String.fromCharCode(...new Uint8Array(byteData))) // 将 ArrayBuffer 转换为 Base64 字符串
        resolve(base64Data)
      }
      reader.readAsArrayBuffer(file.raw)
    } catch (e) {
      reject(e)
    }
  })
}
